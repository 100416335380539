import styled from "styled-components";

const Text = styled.p`
  margin-top: 0;
  max-width: 800px;
  line-height: 1.5;
`;

const About = () => (
  <Text>
    one only knows is a music and audio services house established by the
    electronic music duo, oneohkay. tapping into our love for the music and
    sounds of storytelling mediums like video games and film, we provide a
    wide-ranging variety of audio services focused on contributing to compelling
    narratives and establishing strong audio brands. from sound effects, to
    background music, to scores, and everything in between—we understand that
    audio is a critical part of any project's holistic brand, and we seek to
    maximize its impact to bring projects to their full potential.
  </Text>
);

export default About;
