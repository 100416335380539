import styled from "styled-components";

const Text = styled.p`
  margin-top: 0;
  max-width: 800px;
  line-height: 1.5;
`;

const Contact = () => <Text>hello@oneonlyknows.com</Text>;

export default Contact;
