import { Routes, Route, useLocation, Link } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import styled from "styled-components";
import About from "./About";
import Contact from "./Contact";
import "./anim.css";
import Portfolio from "./Portfolio";
import { useEffect } from "react";

const Page = styled.div`
  padding: 0 40px;
  margin: 40px auto 120px auto;
  min-height: calc(100vh - 160px);
  @media screen and (max-width: 700px) {
    margin-bottom: 200px;
    min-height: calc(100vh - 240px);
  }
`;

const NavArea = styled.div`
  flex: 1;
`;

const PageContent = styled.div`
  margin-top: 10px;
  position: relative;
  align-items: center;
  flex: 1;
  > div {
    position: relative;
    top: 0;
    left: 0;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NavFlex = styled(Content)`
  margin-top: 20px;
  width: 100%;
  position: relative;
`;

const Spotify = styled.div`
  flex: 1;
  margin-top: 8px;
  max-width: 400px;
  @media screen and (max-width: 700px) {
    display: none;
  }
`;

const SpotifyF = styled(Spotify)`
  margin-bottom: 20px;
  display: none;
  @media screen and (max-width: 700px) {
    display: block;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 80px);
  position: absolute;
  bottom: 0;
  padding-bottom: 40px;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

const Title = styled(Link)`
  text-decoration: none;
  font-weight: 200;
  font-size: 32px;
  display: inline-block;
  margin-bottom: 10px;
`;

const Nav = styled.div`
  display: flex;
`;

const FNav = styled(Nav)`
  flex-direction: column;
  align-items: flex-end;
  span {
    font-size: 12px;
    margin-top: 5px;
  }
`;

const NavLink = styled(Link)`
  margin-right: 20px;
  text-decoration: none;
  padding: 5px 0;
  border-bottom: 1px solid
    ${(props) => (props.active ? "white" : "rgba(0,0,0,0)")};
  transition: border-color 0.5s;
`;

const Logo = styled.div`
  display: flex;
  height: calc(100vh - 266px);
  width: 100%;
  align-items: center;
  justify-content: center;
  display: none;
  img {
    max-width: 200px;
    max-height: 200px;
  }
`;

const FooterLogo = styled.div`
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.5s;
  img {
    max-width: 60px;
    max-height: 60px;
  }
  @media screen and (max-width: 700px) {
    display: none;
  }
`;

const IconLink = styled.a`
  text-decoration: none;
  margin-left: 15px;
  img {
    width: 24px;
    height: 24px;
    color: white;
  }
`;

function App() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      document.querySelector("video").classList.add("active");
    } else {
      document.querySelector("video").classList.remove("active");
    }
  }, [location.pathname]);

  return (
    <Page>
      <NavFlex>
        <NavArea>
          <Title to="/">one only knows</Title>
          <Nav>
            <NavLink to="/about" active={location.pathname === "/about"}>
              about
            </NavLink>
            <NavLink
              to="/portfolio"
              active={location.pathname === "/portfolio"}
            >
              portfolio
            </NavLink>
            <NavLink to="/contact" active={location.pathname === "/contact"}>
              contact
            </NavLink>
          </Nav>
        </NavArea>
        <Spotify>
          <iframe
            title="Spotify"
            style={{
              borderRadius: "12px",
            }}
            src="https://open.spotify.com/embed/artist/0VeWlMDxZcMinxtSWUDYWY?utm_source=generator"
            width="100%"
            height="80"
            frameBorder="0"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          ></iframe>
        </Spotify>
      </NavFlex>
      <Content>
        <PageContent>
          <TransitionGroup component={null}>
            <CSSTransition key={location.key} classNames="fade" timeout={500}>
              <Routes location={location}>
                <Route path="/about" element={<About />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/contact" element={<Contact />} />
                <Route
                  path="*"
                  element={
                    <Logo>
                      <img src="/logowhite.png" alt="oneohkay logo" />
                    </Logo>
                  }
                />
              </Routes>
            </CSSTransition>
          </TransitionGroup>
        </PageContent>
      </Content>
      <Footer>
        <SpotifyF>
          <iframe
            title="Spotify"
            style={{
              borderRadius: "12px",
            }}
            src="https://open.spotify.com/embed/artist/0VeWlMDxZcMinxtSWUDYWY?utm_source=generator"
            width="100%"
            height="80"
            frameBorder="0"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          ></iframe>
        </SpotifyF>
        <FooterLogo visible={location.pathname !== "/"}>
          <img src="/logowhite.png" alt="oneohkay logo" />
        </FooterLogo>
        <FNav>
          <div>
            <IconLink href="https://open.spotify.com/artist/0VeWlMDxZcMinxtSWUDYWY">
              <img src="/svgs/spotify.svg" alt="Spotify" />
            </IconLink>
            <IconLink href="https://twitter.com/oneohkay">
              <img src="/svgs/twitter.svg" alt="Twitter" />
            </IconLink>
            <IconLink href="https://www.instagram.com/oneohkay/">
              <img src="/svgs/instagram.svg" alt="Instagram" />
            </IconLink>
            <IconLink href="https://youtube.com/channel/UCGb1Y_Sc5raRYXyQBG-NC2g">
              <img src="/svgs/youtube.svg" alt="YouTube" />
            </IconLink>
            <IconLink href="https://www.facebook.com/oneohkay/">
              <img src="/svgs/facebook.svg" alt="Facebook" />
            </IconLink>
            <IconLink href="https://oneohkay.com">
              <img src="/svgs/tiktok.svg" alt="TikTok" />
            </IconLink>
          </div>
          <span>&copy; one only knows</span>
        </FNav>
      </Footer>
    </Page>
  );
}

export default App;
